export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        token: action.token,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      return {
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
