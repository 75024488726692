import CommonApi from '../CommonApi';

class ChargerService {
  constructor() {
    this.commonApi = new CommonApi();
  }

  userChargers = () => {
    return this.commonApi.get('api/v1/user-chargers');
  };

  addCharger = params => {
    return this.commonApi.post('api/v1/add-charger', params);
  };

  deleteCharger = id => {
    return this.commonApi.delete(`api/v1/delete-charger/${id}`);
  };

  updateCharger = (id, params) => {
    return this.commonApi.put(`api/v1/update-charger/${id}`, params);
  };
}

export default ChargerService;
