// Places Reducer

const placesReducerDefaultState = [];

export default (state = placesReducerDefaultState, action) => {
  switch (action.type) {
    case 'STORE_PLACES':
      return action.places;
    default:
      return state;
  }
};
