import React, { useState, useEffect } from 'react';
import { Button, Container, Card, CardBody, Table } from 'reactstrap';
import ChargerService from '../../networking/services/ChargerService';
import * as Feather from 'react-feather';
import { Loader } from '../../MainViews';
import AddChargerModal from '../modals/AddChargerModal';
import { connect } from 'react-redux';

function ChargersPage(props) {
  /* PROPERTIES
   ----------------------------- */

  // VIEW
  const [modalShow, setModalShow] = useState(false);
  const [chargers, setChargers] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // NETWORKING
  const [selectedCharger, setSelectedCharger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargerService] = useState(() => new ChargerService());

  /* LIFE CYCLE
   ----------------------------- */
  useEffect(() => {
    getChargers();
  }, []);

  /* FETCH
   ----------------------------- */
  const getChargers = async () => {
    try {
      setLoading(true);
      const response = await chargerService.userChargers();
      setLoading(false);
      const responseChargers = response.data.data;
      if (responseChargers) {
        setChargers(responseChargers);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* ACTIONS
   ----------------------------- */
  const didTapAddCharger = async (charger) => {
    console.log('Add Charger ->', charger);
    delete charger._id;
    try {
      setLoading(true);
      const response = await chargerService.addCharger(charger);
      setLoading(false);
      if (response) {
        getChargers();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const didTapDeleteCharger = async (e) => {
    console.log('Delete charger with id ->', id);
    const id = e.target.value;
    try {
      setLoading(true);
      const response = await chargerService.deleteCharger(id);
      setLoading(false);
      if (response) {
        getChargers();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const didTapUpdateCharger = async (charger) => {
    console.log('Update Charger ->', charger);
    try {
      setLoading(true);
      const response = await chargerService.updateCharger(charger._id, charger);
      setLoading(false);
      if (response) {
        getChargers();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* VIEW ACTIONS
   ----------------------------- */
  const editChargerTapped = (charger) => {
    console.log(charger);
    setSelectedCharger(charger);
    setEditMode(true);
    setModalShow(true);
  };

  const onHide = () => {
    setModalShow(false);
    setEditMode(false);
  };

  // Methods
  const getConnectorType = (typeId) => {
    console.log(typeId);
    console.log(props);
    const type = props.types.connectorTypes.find((type) => type._id === typeId);
    if (type) {
      return type.title;
    } else {
      return '';
    }
  };

  /* VIEWS
   ----------------------------- */
  return (
    <Container fluid>
      <div className="p-a-md d-flex justify-content-end">
        {modalShow ? (
          <AddChargerModal
            isOpen={modalShow}
            onHide={() => onHide()}
            didTapAddCharger={(charger) => didTapAddCharger(charger)}
            didTapUpdateCharger={(charger) => didTapUpdateCharger(charger)}
            selectedCharger={selectedCharger}
            editMode={editMode}
          />
        ) : null}
      </div>

      {loading ? (
        <div className="p-t-xxl">
          <Loader type="dots" />
        </div>
      ) : (
        <div>
          <Container fluid>
            <div className="p-b-md d-flex justify-content-end">
              <Button color="primary" onClick={() => setModalShow(true)}>
                <Feather.PlusCircle className="p-r-sm" />
                Add Charger
              </Button>{' '}
            </div>

            <Card>
              <CardBody>
                <div className="p-a-md">Your Chargers</div>
                <Table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Place</th>
                      <th>Price Rate (€ / hr)</th>
                      <th>Serving Hours</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {chargers.map((charger) => (
                      <tr key={charger._id}>
                        <td>{getConnectorType(charger.connector_type)}</td>
                        <td>{charger.place.title}</td>
                        <td>{charger.price_rate}</td>
                        <td>24 hr</td>
                        <td>
                          <Button
                            className="m-r-sm"
                            style={{ border: 'none', backgroundColor: '#FFFFFF', outline: 'none' }}
                            onClick={() => editChargerTapped(charger)}
                          >
                            <Feather.Edit />
                          </Button>
                          <Button
                            className="m-r-sm"
                            style={{ border: 'none', backgroundColor: '#FFFFFF', outline: 'none' }}
                            value={charger._id}
                            onClick={(e) => didTapDeleteCharger(e)}
                          >
                            <Feather.Trash2 />
                          </Button>
                          <Button
                            className="m-r-sm"
                            style={{ border: 'none', backgroundColor: '#FFFFFF', outline: 'none' }}
                          >
                            <Feather.Info />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    types: state.info,
  };
};

export default connect(mapStateToProps)(ChargersPage);
