// Register
export const register = () => ({
  type: 'REGISTER',
});

// Login
export const login = token => ({
  type: 'LOGIN',
  token,
});

// export const startLogin = () => {
//   return () => {
//     return;
//   };
// };

// Logout
export const logout = () => ({
  type: 'LOGOUT',
});
