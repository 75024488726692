import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { connect } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from 'reactstrap';

class AddPlaceModal extends React.Component {
  /* PROPERTIES
    ----------------------------- */
  state = {
    _id: '',
    description: '',
    image: '',
    title: '',
    category: null,
    type: null,
    location: {
      coordinates: [],
      formattedAddress: '',
    },
  };

  /* LIFE CYCLE
    ----------------------------- */
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.editMode) {
      this.setState({ ...this.props.selectedPlace });
    }
  };

  /* INPUT CHANGE
   ----------------------------- */
  handleLocationChange = (event) => {
    let location = { ...this.state.location };
    if (event.target.name === 'lang') {
      const newLang = Number(event.target.value);
      location.coordinates[0] = newLang;
    } else if (event.target.name === 'long') {
      const newLong = Number(event.target.value);
      location.coordinates[1] = newLong;
    } else {
      location.formattedAddress = event.target.value;
    }
    this.setState({ location: location }, () => console.log(this.state));
  };

  handleInputChange = (event) => {
    event.persist();
    this.setState(
      (prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }),
      () => console.log(this.state)
    );
  };

  /* ACTIONS
   ----------------------------- */
  didTapSaveButton = (event) => {
    this.props.didTapAddPlace(this.state);
    this.props.onHide();
  };

  didTapUpdateButton = (event) => {
    event.preventDefault();
    this.props.didTapUpdatePlace(this.state);
    this.props.onHide();
  };

  didTapDeleteButton = (event) => {
    event.preventDefault();
    this.props.didTapDeleteplace(this.props.selectedPlace._id);
    this.props.onHide();
  };

  /* VIEWS
   ----------------------------- */
  render() {
    return (
      <div>
        {/* {console.log(this.props.selectedPlace)} */}
        <Modal isOpen={this.props.isOpen} centered size="xl" style={{ width: '80%' }}>
          <Form onSubmit={this.didTapSaveButton}>
            <ModalHeader>Places</ModalHeader>
            <ModalBody>
              <div>
                <h3 className="m-b">{this.props.editMode ? 'Edit Place' : 'New Place'}</h3>
                <Row>
                  <Col md={8}>
                    <Card>
                      <CardBody>
                        <FormGroup>
                          <Label for="exampleText">Title</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            onChange={this.handleInputChange}
                            required
                            defaultValue={this.state.title}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleText">Image</Label>
                          <Input
                            type="text"
                            name="image"
                            id="image"
                            onChange={this.handleInputChange}
                            required
                            defaultValue={this.state.image}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleText">Description</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            style={{ height: 300 }}
                            onChange={this.handleInputChange}
                            defaultValue={this.state.description}
                            required
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    {/* <GooglePlacesAutocomplete /> */}
                    {/* <Button block className="m-b">
                    Add Location
                  </Button> */}
                    <FormGroup>
                      <Label for="exampleText">Lang</Label>
                      <Input
                        type="text"
                        name="lang"
                        id="lang"
                        onChange={this.handleLocationChange}
                        required
                        defaultValue={this.state.location.coordinates[0]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleText">Long</Label>
                      <Input
                        type="text"
                        name="long"
                        id="long"
                        onChange={this.handleLocationChange}
                        required
                        defaultValue={this.state.location.coordinates[1]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleText">Address</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        onChange={this.handleLocationChange}
                        required
                        defaultValue={this.state.location.formattedAddress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="select">Category</Label>
                      <Input type="select" name="category" id="category" required onChange={this.handleInputChange}>
                        <option name="category" value={''}>
                          -
                        </option>
                        {this.props.types.categories.map((category) => (
                          <option
                            name="category"
                            value={category._id}
                            key={category._id}
                            selected={this.state.category === category._id ? true : false}
                          >
                            {category.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="select">Type</Label>
                      <Input type="select" name="type" id="type" required onChange={this.handleInputChange}>
                        <option name="type" value={''}>
                          -
                        </option>
                        {this.props.types.place_types.map((type) => (
                          <option
                            name="type"
                            value={type._id}
                            key={type._id}
                            selected={this.state.type === type._id ? true : false}
                          >
                            {type.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* <Card>
                      <CardHeader>Details</CardHeader>
                      <CardBody>
                        <div>
                          <strong>Country:</strong> Greece
                        </div>
                        <hr />
                        <div>
                          <strong>City:</strong> Thessaloniki
                        </div>
                        <hr />
                      </CardBody>
                    </Card> */}
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              {this.props.editMode ? (
                <Button color="danger" onClick={this.didTapDeleteButton}>
                  Delete
                </Button>
              ) : null}
              <Button color="secondary" onClick={this.props.onHide}>
                Cancel
              </Button>
              {this.props.editMode ? (
                <Button color="primary" onClick={this.didTapUpdateButton}>
                  Update
                </Button>
              ) : (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  types: state.info,
});

export default connect(mapStateToProps)(AddPlaceModal);
