export default {
  top: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'PieChart',
    },
    {
      name: 'Reservations',
      url: '/reservations',
      icon: 'BookOpen',
    },
    {
      name: 'Places',
      url: '/places',
      icon: 'MapPin',
    },
    {
      name: 'Chargers',
      url: '/chargers',
      icon: 'BatteryCharging',
    },
    {
      name: 'Events',
      url: '/events',
      icon: 'Calendar',
    },
    {
      name: 'Discounts',
      url: '/discounts',
      icon: 'ShoppingBag',
    },
    {
      name: 'Account',
      url: '/account',
      icon: 'User',
      children: [
        {
          name: 'Profile',
          url: '/account/profile',
        },
        {
          name: 'Settings',
          url: '/account/settings',
        },
      ],
    },
  ],
  bottom: [
    {
      name: 'Get Chargit App',
      url: 'https://chargit.gr',
      icon: 'Smile',
      external: true,
      target: '_blank',
    },
  ],
};
