import InfoService from '../../networking/services/InfoService';

// SET_LANDING
export const storeTypes = (types) => ({
  type: 'SET_TYPES',
  types,
});

export const startSetTypes = () => {
  return (dispatch) => {
    return new InfoService()
      .landing()
      .then((response) => {
        const types = response.data.data;
        dispatch(storeTypes(types));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
