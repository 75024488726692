import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

// Routing
import createHistory from 'history/createBrowserHistory';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Pages
import DashboardLayout from '../views/layouts/DashboardLayout';
import LoginPage from '../views/pages/LoginPage';
import NotFoundPage from '../views/pages/templates/404';

export const history = createHistory();

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute path="/" component={LoginPage} exact={true} />
      <PublicRoute path="/login" component={LoginPage} />
      <PrivateRoute path="/dashboard" component={DashboardLayout} />
      <PrivateRoute path="/reservations" component={DashboardLayout} />
      <PrivateRoute path="/places" component={DashboardLayout} />
      <PrivateRoute path="/chargers" component={DashboardLayout} />
      <PrivateRoute path="/events" component={DashboardLayout} />
      <PrivateRoute path="/discounts" component={DashboardLayout} />
      <PrivateRoute path="/account/profile" component={DashboardLayout} />
      <PrivateRoute path="/account/settings" component={DashboardLayout} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default AppRouter;
