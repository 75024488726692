import * as axios from 'axios';
import { getCookie } from '../managers/cookieManager';

export default class CommonApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.BASE_API_ENDPOINT || 'https://chargit.herokuapp.com'; // 'http://localhost:3977
  }

  init = () => {
    this.api_token = getCookie('token');

    let headers = {
      Access: 'application/json',
    };

    if (this.api_token) {
      headers.Authorization = `${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };

  get = (endpoind, params) => {
    return this.init().get(`/${endpoind}`, { ...params });
  };

  post = (endpoind, params) => {
    return this.init().post(`/${endpoind}`, { ...params });
  };

  put = (endpoind, params) => {
    return this.init().put(`/${endpoind}`, { ...params });
  };

  delete = (endpoind, params) => {
    return this.init().delete(`/${endpoind}`, { ...params });
  };
}
