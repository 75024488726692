import CommonApi from '../CommonApi';

class InfoService {
  constructor() {
    this.commonApi = new CommonApi();
  }

  landing = () => {
    return this.commonApi.get('api/v1/types');
  };
}

export default InfoService;
