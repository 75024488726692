import React from 'react';
import { connect } from 'react-redux';
import profileIcon from '../../assets/images/profile.png';
import profilebackground from '../../assets/images/profileBackground.jpg';
import { Row, Button, Col, CardHeader, CardTitle, Container, Form, FormGroup, Input, Card, CardBody } from 'reactstrap';

const CustomerProfile = (props) => {
  return (
    <div>
      {console.log(props)}
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <CardTitle as="h4">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Company (disabled)</label>
                        <Input defaultValue={props.profile.company} disabled type="text"></Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <Input type="email" disabled defaultValue={props.profile.email}></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input defaultValue={props.profile.first_name} type="text"></Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input defaultValue={props.profile.last_name} type="text"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input defaultValue={props.profile.address} type="text"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input defaultValue="Athens" type="text"></Input>
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input defaultValue="Greece" type="text"></Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="54641" type="number"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button className="btn-fill pull-right" type="submit" variant="info" color="primary">
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <div>
                <img alt="..." style={{ maxHeight: '200px', width: '100%' }} src={profilebackground}></img>
              </div>
              <CardBody>
                <div className="text-center">
                  <img alt="..." src={profileIcon} className="avatar avatar-lg"></img>
                  <h5 className="title">
                    {props.profile.first_name || ''} {props.profile.last_name || ''}
                  </h5>
                  <p className="description">{props.profile.email}</p>
                </div>
                <p className="description text-center">
                  "Lorem ipsum dolor sit amet, <br></br>
                  consectetur adipiscing elit, <br></br>
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </CardBody>
              <hr></hr>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
  };
};

export default connect(mapStateToProps)(CustomerProfile);
