import Analytics from './pages/Analytics';
import ReservationsPage from './pages/ReservationsPage';
import Chargers from './pages/ChargersPage';
import ComingSoonPage from './pages/ComingSoonPage';
import Places from './pages/PlacesPage';
import CustomerProfile from './pages/CustomerProfile';
import SettingsPage from './pages/SettingsPage';

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Analytics,
  },
  {
    name: 'Places',
    path: '/places',
    component: Places,
  },
  {
    name: 'Chargers',
    path: '/chargers',
    component: Chargers,
  },
  {
    name: 'Events',
    path: '/events',
    component: ComingSoonPage,
  },
  {
    name: 'Discounts',
    path: '/discounts',
    component: ComingSoonPage,
  },
  {
    name: 'Reservations',
    path: '/reservations',
    component: ReservationsPage,
  },
  {
    name: 'Profile',
    path: '/account/profile',
    component: CustomerProfile,
  },
  {
    name: 'Settings',
    path: '/account/settings',
    component: SettingsPage,
  },
];

export default pageList;
