import React from 'react';

const SettingsPage = () => {
  return (
    <div>
      <span />
    </div>
  );
};

export default SettingsPage;
