import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import placeReducer from '../reducers/place';
import profileReducer from '../reducers/profile';
import infoReducer from '../reducers/info';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      places: placeReducer,
      profile: profileReducer,
      info: infoReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
