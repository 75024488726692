// Info Reducer

const typesReducerDefaultState = {};

export default (state = typesReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_TYPES':
      return action.types;
    default:
      return state;
  }
};
