import React from 'react';
import Lottie from 'react-lottie';

const animationData = require('../../assets/animations/comming-soon.json');
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ComingSoonPage = () => {
  return (
    <div>
      <div className="m-t-xxl text-center">
        <h3 className="m-b">Coming Soon</h3>
        <Lottie options={defaultOptions} height={390} width={500} />
      </div>
    </div>
  );
};

export default ComingSoonPage;
