import React, { useState, useEffect } from 'react';
import { Row, Button, Container, Col } from 'reactstrap';
import PlaceView from '../../views/reusable/PlaceView';
import AddPlaceModal from '../modals/AddPlaceModal';
import hotelImage from '../../assets/images/hotel.jpg';
import PlaceService from '../../networking/services/PlaceService';
import { Loader } from '../../MainViews';
import * as Feather from 'react-feather';

// Redux Store
import { connect } from 'react-redux';
import { storePlaces } from '../../redux/actions/place';

function Places(props) {
  /* PROPERTIES
   ----------------------------- */

  // VIEW
  const [modalShow, setModalShow] = useState(false);
  const [places, setPlaces] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // NETWORKING
  const [selectedPlace, setSelectedPlace] = useState(false);
  const [loading, setLoading] = useState(false);
  const [placeService] = useState(() => new PlaceService());

  /* LIFE CYCLE
   ----------------------------- */
  useEffect(() => {
    getPlaces();
  }, []);

  /* FETCH
   ----------------------------- */
  const getPlaces = async () => {
    try {
      setLoading(true);
      const response = await placeService.userPlaces();
      const responsePlaces = response.data.data;
      if (responsePlaces) {
        props.storePlaces(responsePlaces);
        setPlaces(responsePlaces);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  /* ACTIONS
   ----------------------------- */
  const didTapAddPlace = async (place) => {
    console.log('Add Place ->', place);
    delete place._id;
    try {
      setLoading(true);
      const response = await placeService.addPlace(place);
      setLoading(false);
      if (response) {
        getPlaces();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const didTapDeleteplace = async (id) => {
    console.log('Delete place with id ->', id);
    try {
      setLoading(true);
      const response = await placeService.deletePlace(id);
      const newPlaces = places.filter((place) => place._id !== id);
      setLoading(false);
      if (response) {
        setPlaces(newPlaces);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const didTapUpdatePlace = async (place) => {
    console.log('Update Place ->', place);
    try {
      setLoading(true);
      const response = await placeService.updatePlace(place._id, place);
      setLoading(false);
      if (response) {
        getPlaces();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* VIEW ACTIONS
   ----------------------------- */
  const editPlaceTapped = (place) => {
    setSelectedPlace(place);
    setEditMode(true);
    setModalShow(true);
  };

  const onHide = () => {
    setModalShow(false);
    setEditMode(false);
  };

  /* VIEWS
   ----------------------------- */
  return (
    <Container fluid>
      <div className="p-a-md d-flex justify-content-end">
        <Button color="primary" onClick={() => setModalShow(true)}>
          <Feather.PlusCircle className="p-r-sm" />
          Add Place
        </Button>{' '}
        {modalShow ? (
          <AddPlaceModal
            isOpen={modalShow}
            onHide={() => onHide()}
            didTapAddPlace={(place) => didTapAddPlace(place)}
            didTapUpdatePlace={(place) => didTapUpdatePlace(place)}
            didTapDeleteplace={(id) => didTapDeleteplace(id)}
            selectedPlace={selectedPlace}
            editMode={editMode}
          />
        ) : null}
      </div>

      {loading ? (
        <div className="p-t-xxl">
          <Loader type="dots" />
        </div>
      ) : (
        <div>
          <Row>
            {places.map((place) => (
              <Col key={place._id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <PlaceView key={place._id} place={place} image={hotelImage} editPlaceTapped={editPlaceTapped} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  );
}

const mapDispatchToPros = (dispatch, state) => ({
  storePlaces: (places) => dispatch(storePlaces(places)),
});

export default connect(null, mapDispatchToPros)(Places);
