import LoginService from '../../networking/services/LoginService';

// SET_PROFILE

export const setProfile = profile => ({
  type: 'SET_PROFILE',
  profile,
});

export const startSetProfile = () => {
  return dispatch => {
    return new LoginService()
      .getProfile()
      .then(response => {
        const profile = response.data.data;
        dispatch(setProfile(profile));
      })
      .catch(err => {
        console.log(err);
      });
  };
};
