import PlaceService from '../../networking/services/PlaceService';

// STORE_PLACES

export const storePlaces = (places) => ({
  type: 'STORE_PLACES',
  places,
});

export const startSetPlaces = () => {
  return (dispatch) => {
    return new PlaceService()
      .userPlaces()
      .then((response) => {
        const places = response.data.data;
        dispatch(storePlaces(places));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
