import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Modal,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from 'reactstrap';

class AddChargerModal extends React.Component {
  /* PROPERTIES
    ----------------------------- */
  state = {
    _id: null,
    description: null,
    title: null,
    place: {},
    is_full_day: true,
    charging_power: null,
    is_free: null,
    price_rate: null,
    connector_type: null,
    open_date: null,
    close_date: null,
  };

  /* LIFE CYCLE
    ----------------------------- */
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.editMode) {
      this.setState({ ...this.props.selectedCharger });
    }
  };

  /* INPUT CHANGE
   ----------------------------- */
  handleInputChange = (event) => {
    event.persist();
    this.setState(
      (prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }),
      () => console.log(this.state)
    );
  };

  /* ACTIONS
   ----------------------------- */
  didTapSaveButton = (event) => {
    this.props.didTapAddCharger(this.state);
    this.props.onHide();
  };

  didTapUpdateButton = (event) => {
    event.preventDefault();
    this.props.didTapUpdateCharger(this.state);
    this.props.onHide();
  };

  didTapDeleteButton = (event) => {
    event.preventDefault();
    this.props.didTapDeleteCharger(this.props.selectedCharger._id);
    this.props.onHide();
  };

  /* VIEWS
   ----------------------------- */
  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} centered size="xl" style={{ width: '80%' }}>
          <Form onSubmit={this.didTapSaveButton}>
            <ModalHeader>Chargers</ModalHeader>
            <ModalBody>
              <div>
                <h3 className="m-b">{this.props.editMode ? 'Edit Charger' : 'New Charger'}</h3>
                <Row>
                  <Col md={8}>
                    <Card>
                      <CardBody>
                        <FormGroup>
                          <Label for="exampleText">Title</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            onChange={this.handleInputChange}
                            required
                            defaultValue={this.state.title}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleText">Charging Power</Label>
                          <Input
                            type="number"
                            name="charging_power"
                            id="charging_power"
                            onChange={this.handleInputChange}
                            required
                            defaultValue={this.state.charging_power}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleText">Description</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            style={{ height: 300 }}
                            onChange={this.handleInputChange}
                            defaultValue={this.state.description}
                            required
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="p-t-md">
                      <Label for="exampleText">Price rate (€ / hr)</Label>
                      <Input
                        type="number"
                        name="price_rate"
                        id="price_rate"
                        patern="^(\d*\.)?\d+$"
                        onChange={this.handleInputChange}
                        defaultValue={this.state.price_rate}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="select">Place</Label>
                      <Input type="select" name="place" id="place" required onChange={this.handleInputChange}>
                        <option name="place" value={''}>
                          -
                        </option>
                        {this.props.places.map((place) => (
                          <option
                            name="place"
                            value={place._id}
                            key={place._id}
                            selected={this.state.place._id === place._id ? true : false}
                          >
                            {place.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="select">Connector Type</Label>
                      <Input
                        type="select"
                        name="connector_type"
                        id="connector_type"
                        required
                        onChange={this.handleInputChange}
                      >
                        <option name="connector_type" value={''}>
                          -
                        </option>
                        {this.props.types.connectorTypes.map((connectorType) => (
                          <option
                            name="connector_type"
                            value={connectorType._id}
                            key={connectorType._id}
                            selected={this.state.connector_type === connectorType._id ? true : false}
                          >
                            {connectorType.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="select">Operator</Label>
                      <Input type="select" name="operator" id="operator" required onChange={this.handleInputChange}>
                        <option name="operator" value={''}>
                          -
                        </option>
                        {this.props.types.operators.map((operator) => (
                          <option
                            name="operator"
                            value={operator._id}
                            key={operator._id}
                            selected={this.state.operator === operator._id ? true : false}
                          >
                            {operator.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <div>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="is_full_day"
                            id="is_full_day"
                            checked={this.state.is_full_day}
                            onChange={(e) => this.setState({ is_full_day: e.target.checked })}
                          />{' '}
                          24/7
                        </Label>
                      </FormGroup>
                      {this.state.is_full_day ? null : (
                        <div>
                          <FormGroup className="p-t-md">
                            <Label for="exampleText">Open Date</Label>
                            <DatePicker
                              selected={this.state.open_date}
                              showTimeSelect
                              onChange={(date) => this.setState({ open_date: date })}
                              required
                            />
                            <hr />
                          </FormGroup>
                          <FormGroup className="p-t-md">
                            <Label for="exampleText">Close Date</Label>
                            <DatePicker
                              selected={this.state.close_date}
                              showTimeSelect
                              onChange={(date) => this.setState({ close_date: date })}
                              required
                            />
                          </FormGroup>
                          <hr />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.props.onHide}>
                Cancel
              </Button>
              {this.props.editMode ? (
                <Button color="primary" onClick={this.didTapUpdateButton}>
                  Update
                </Button>
              ) : (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    places: state.places,
    types: state.info,
  };
};

export default connect(mapStateToProps)(AddChargerModal);
