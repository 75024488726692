import React from 'react';
import { Button, Card, CardBody, CardImg, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import * as Feather from 'react-feather';

const PlaceView = (props) => (
  <div style={{ paddingRight: '18px' }}>
    <Card>
      <CardImg src={props.place.image} top width="100%" height="200px" />
      <CardBody>
        <CardTitle>{props.place.title}</CardTitle>
        <CardSubtitle>{props.place.subtitle}</CardSubtitle>
        <CardText>{props.place.description}</CardText>
        <Button
          style={{ border: 'none', backgroundColor: '#FFFFFF', outline: 'none' }}
          onClick={(place) => props.editPlaceTapped(props.place)}
        >
          <Feather.Edit />
        </Button>{' '}
      </CardBody>
    </Card>
  </div>
);

export default PlaceView;
