import CommonApi from '../CommonApi';

class PlaceService {
  constructor() {
    this.commonApi = new CommonApi();
  }

  userPlaces = () => {
    return this.commonApi.get('api/v1/user-places');
  };

  addPlace = params => {
    return this.commonApi.post('api/v1/add-place', params);
  };

  deletePlace = id => {
    return this.commonApi.delete(`api/v1/delete-place/${id}`);
  };

  updatePlace = (id, params) => {
    return this.commonApi.put(`api/v1/update-place/${id}`, params);
  };
}

export default PlaceService;
