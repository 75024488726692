import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Container, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import Logo from '../../assets/images/app-logo.svg';
import LoginService from '../../networking/services/LoginService';
import { history } from '../../routers/AppRouter';
import { Loader } from '../../MainViews';

// Redux
import { connect } from 'react-redux';
import { login } from '../../redux/actions/auth';
import { setProfile } from '../../redux/actions/profile';
import { startSetPlaces } from '../../redux/actions/place';
import { startSetTypes } from '../../redux/actions/info';

import { setCookie } from '../../managers/cookieManager';
import { useAlert } from 'react-alert';

const LoginPage = (props) => {
  const [loginService] = useState(() => new LoginService());
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    console.log(props);
  }, []);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const didTapLoginButton = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setLoading(true);

      const loginResponse = await loginService.getToken(inputs.email, inputs.password);
      const token = loginResponse.data.data.accessToken;
      setCookie('token', token);
      props.login(token);

      const profileResponse = await loginService.getProfile();
      const profile = profileResponse.data.data;
      await props.setProfile(profile);

      await props.startSetPlaces();
      await props.startSetTypes();

      history.push('/dashboard');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.show('An error has occured');
    }
  };

  return (
    <div className=".login-page-wrapper">
      <div className="m-t-xxl ">
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 2 }}>
              {loading ? (
                <div className="p-t-xxl">
                  <Loader type="dots" />
                </div>
              ) : (
                <div>
                  <div className="m-a-md d-flex justify-content-center">
                    <img src={Logo} alt="." className="" />
                    <div class="p-l-sm align-self-center">
                      <h3 style={{ marginBottom: '0px' }}>Chargit.</h3>
                    </div>
                  </div>
                  <Card>
                    <CardBody onSubmit={didTapLoginButton}>
                      <Form>
                        <FormGroup>
                          <Label for="name">Email</Label>
                          <Input
                            type="text"
                            name="email"
                            id="email"
                            placeholder=""
                            onChange={handleInputChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="name">Password</Label>
                          <Input
                            type="text"
                            name="password"
                            id="password"
                            placeholder=""
                            onChange={handleInputChange}
                            required
                          />
                        </FormGroup>
                        <Button color="primary" type="submit">
                          Login
                        </Button>{' '}
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const mapDispatchToPros = (dispatch, state) => ({
  login: (token) => dispatch(login(token)),
  setProfile: (profile) => dispatch(setProfile(profile)),
  startSetPlaces: () => dispatch(startSetPlaces()),
  startSetTypes: () => dispatch(startSetTypes()),
});

export default connect(null, mapDispatchToPros)(LoginPage);
