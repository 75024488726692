import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';

// Styles
import './MainViews/scss/styles.scss';

// Routers
import AppRouter from './routers/AppRouter';

// Providers
import { Provider } from 'react-redux';

// Redux Store
import configureStore from './redux/store/configureStore';
import { login } from './redux/actions/auth';
import { startSetPlaces } from './redux/actions/place';
import { startSetTypes } from './redux/actions/info';
import { startSetProfile } from './redux/actions/profile';

// View
import { Loader } from './MainViews';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// Utilities
import { getCookie } from './managers/cookieManager';
require('dotenv').config({ path: './config/.env' });

// Variables
const store = configureStore();
let hasRendered = false;

// Configure Alert options
const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

// Methods
const setupInitialState = async () => {
  const token = getCookie('token');
  if (token) {
    ReactDOM.render(alertJsx, document.getElementById('app'));
    await store.dispatch(login(token));
    await store.dispatch(startSetProfile());
    await store.dispatch(startSetPlaces());
    await store.dispatch(startSetTypes());
    renderApp();
  } else {
    renderApp();
  }
};

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('app'));
    hasRendered = true;
  }
};

const alertJsx = (
  <div className="p-a-xl">
    <Loader type="dots" />
  </div>
);

const jsx = (
  <AlertProvider template={AlertTemplate} {...alertOptions}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </AlertProvider>
);

setupInitialState();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
