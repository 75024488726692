import React, { Component } from 'react';
import { Card, CardBody, Table, Button } from 'reactstrap';

class ReservationsPage extends Component {
  render() {
    return (
      <div>
        <div className="p-a-md d-flex justify-content-end">
          <Button color="primary">Refresh</Button>{' '}
        </div>
        <Card>
          <CardBody>
            <div className="p-b-md">Future Reservations</div>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <div className="p-a-md d-flex justify-content-start">No data found</div>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="p-b-md">Today's Reservations</div>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Tesla</td>
                  <td>Model S</td>
                  <td>1hr</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Audi</td>
                  <td>e-tron</td>
                  <td>45 mins</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>BMW </td>
                  <td>i3</td>
                  <td>1hr</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="p-b-md">Passed Reservations</div>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Tesla</td>
                  <td>Model S</td>
                  <td>1hr</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Audi</td>
                  <td>e-tron</td>
                  <td>45 mins</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>BMW </td>
                  <td>i3</td>
                  <td>1hr</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ReservationsPage;
