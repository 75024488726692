import CommonApi from '../CommonApi';
import { useAlert } from 'react-alert';

class LoginService {
  constructor() {
    this.commonApi = new CommonApi();
  }

  getToken = (email, password) => {
    return this.commonApi.post('api/v1/sign-in', {
      email: email,
      password: password,
    });
  };

  getProfile = () => {
    return this.commonApi.get('api/v1/profile', null);
  };
}

export default LoginService;
