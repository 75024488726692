export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        profile: action.profile,
      };
    default:
      return state;
  }
};
